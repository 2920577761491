<template>
  <section class="content-section kb-table-section">
    <header class="section-header">
      <h4 class="list-title">■ 평가항목 및 배점</h4>
        <p>① 일반직원 (L직급) </p>
    </header>
    <div class="section-content">
      <div class="content-item kb-table-contents">
        <!-- [yyy:22-11-29] -->
        <div class="kb-table kb-table-v2">
          <table style="min-width:600px" >
            <colgroup>
              <col width="144px">
<!--              <col width="168px">-->
<!--              <col width="152px">-->
<!--              <col width="168px">-->
            </colgroup>
            <thead class="align-center">
            <tr>
              <th><span class="th-title text-gold">구분</span></th>
              <th class="left-border"><span class="th-title text-gold">L0</span></th>
              <th><span class="th-title text-gold">L1</span></th>
              <th><span class="th-title text-gold">L2</span></th>
              <th><span class="th-title text-gold">L3</span></th>

            </tr>
             </thead>
            <tbody class="align-center">
          <tr>
            <td><span class="td-text text-gold">신임과정 <sup>주1)</sup></span></td>
            <td class="left-border"><span class="td-text">-</span></td>
            <td><span class="td-text">-</span></td>
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">40점</span></td>
          </tr>
          <tr>
            <td><span class="td-text text-gold">직무필수과정/승격예비과정 <sup>주2)</sup></span></td>
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
          </tr>
          <tr>
            <td><span class="td-text text-gold">KB은행실무종합과정 <sup>주3)</sup></span></td>
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">-</span></td>
            <td><span class="td-text">-</span></td>
          </tr>
          <tr>
            <td><span class="td-text text-gold">계</span></td>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
          </tr>
          </tbody>

          </table>
        </div>
        <!-- //[yyy:22-11-29] -->

      </div>
      <div style="padding: 16px">
        <p class="text text-muted">주1) 신임과정은 이수자에 대하여 40점을 부여함</p>
        <p class="text text-muted">주2) 직급별 이수해야 하는 직무 필수과정과 승격예비과정을 모두 수료한 후 각 취득 점수의 산술평균점수를 100분 비율로 환산하여 배점에 곱한 점수를 부여함</p>
        <p class="text text-muted">주3) 당해 직급의 상위 성적 2회분의 평균 성적을 100분 비율로 환산하여 배점에 곱한 점수를 부여함 (단, 2회 미만 응시자의 경우 1회분의 성적을 적용)</p>
      </div>
    </div>

  </section>

  <section class="content-section kb-table-section">
    <header class="section-header">
      <p class="title-desc">② 전문직원 (S직급)</p>
    </header>
    <div class="section-content">
      <div class="content-item kb-table-contents">
        <!-- [yyy:22-11-29] -->
        <div class="kb-table kb-table-v2">
          <table style="min-width:600px" >
            <colgroup>
              <col width="144px">
              <!--              <col width="168px">-->
              <!--              <col width="152px">-->
              <!--              <col width="168px">-->
            </colgroup>
            <thead class="align-center">
            <tr>
              <th><span class="th-title text-gold">구분</span></th>
              <th class="left-border"><span class="th-title text-gold">S1</span></th>
              <th><span class="th-title text-gold">S2</span></th>
              <th><span class="th-title text-gold">S3</span></th>
            </tr>
            </thead>
            <tbody class="align-center">
            <tr>
              <td><span class="td-text text-gold">신임과정</span></td>
              <td class="left-border"><span class="td-text">-</span></td>
              <td><span class="td-text">40점</span></td>
              <td><span class="td-text">40점</span></td>
            </tr>
            <tr>
              <td><span class="td-text text-gold">승격예비과정</span></td>
              <td><span class="td-text">100점</span></td>
              <td><span class="td-text">60점</span></td>
              <td><span class="td-text">60점</span></td>
            </tr>
            <tr>
              <td><span class="td-text text-gold">계</span></td>
              <td><span class="td-text">100점</span></td>
              <td><span class="td-text">100점</span></td>
              <td><span class="td-text">100점</span></td>
            </tr>
            </tbody>

          </table>
        </div>
        <!-- //[yyy:22-11-29] -->
      </div>
    </div>

  </section>


  <section class="content-section">
    <header class="section-header">
      <h4 class="title">자격증취득 (가점항목)</h4>
      <p class="header-sub-title">
        <b>평가대상</b>
        <br>상품판매 필수 자격증 + 기타 보유 등급별 자격증 </p>
    </header>
    <div class="section-content">
      <div class="content-item">
        <div class="list-title">
          <h5>L1</h5>
        </div>
        <ol class="adc-list justify">
          <li>
            <h6>최대가점</h6>
            <p>10점</p>
          </li>
          <li>
            <h6>영업점 상품판매 필수 자격증</h6>
            <p>각 1점</p>
          </li>
          <li>
            <h6>기타 보유 등급별 자격증</h6>
            <p>최대 7점</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>L2</h5>
        </div>
        <ol class="adc-list justify">
          <li>
            <h6>최대가점</h6>
            <p>5점</p>
          </li>
          <li>
            <h6>상품판매 필수자격증</h6>
            <p>각 1점</p>
          </li>
          <li>
            <h6>기타 보유 등급별 자격증</h6>
            <p>최대 5점</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <p class="silver">
          <strong>상품판매 필수자격증</strong><br>
          펀드투자권유자문인력, 파생상품투자권유자문인력, 보험4종(생명,손해,제3,변액)
        </p>
        <p class="silver">
          <strong>기타 보유 등급별 자격증</strong><br>
          A등급(5), B등급(4), C등급(3), D등급(2), E등급(1) <br>
          ※ 연수규정 > [별표4] ‘자격증별 마일리지 부여기준’
        </p>
      </div>
    </div>
  </section>

</template>
<script>

export default {
  name: "MobileCapabilitySystemTrain",
}
</script>
