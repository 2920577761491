<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">필수이수학점(100점)</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <ol class="adc-list justify">
          <li>
            <h6>대상직원</h6>
            <p>일반직원(L0 ~ L3) </p>
          </li>
          <li>
            <h6>평가대상</h6>
            <p class="right-align">필수과정(신임과정, 승격예비과정,<br>
              직무 필수과정) 이수실적</p>
          </li>
        </ol>
      </div>
    </div>
    <div class="section-content">
      <div class="content-item grade">
        <h4 class="title">신임과정(40점) + {승격예비·직무필수과정(60점)
          × 취득성적/100}</h4>
        <ol class="adc-list wd-112">
          <li>
            <h6>취득성적</h6>
            <p>직무 필수 과정 점수와 승격예비과정 종합평가점수의 산술평균<br>
              L0/L1직원은 KB은행실무종합과정<br>
              (舊직원실무평가)로 대체하여 평가</p>
          </li>
          <li>
            <h6>평가산식</h6>
            <p>(성적 상위 2회분의 KB은행실무종합과정 평균성적)/100 × 40점</p>
          </li>
        </ol>
      </div>
    </div>
  </section>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">자격증취득 (가점항목)</h4>
      <p class="header-sub-title">
        <b>평가대상</b>
        <br>영업점 상품판매 필수 자격증 + 기타 보유 등급별 자격증</p>
    </header>
    <div class="section-content">
      <div class="content-item">
        <div class="list-title">
          <h5>L1</h5>
        </div>
        <ol class="adc-list justify">
          <li>
            <h6>최대가점</h6>
            <p>10점(2022년 기준)</p>
          </li>
          <li>
            <h6>영업점 상품판매 필수 자격증</h6>
            <p>각 1점</p>
          </li>
          <li>
            <h6>기타 보유 등급별 자격증</h6>
            <p>최대 7점</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>L2</h5>
        </div>
        <ol class="adc-list justify">
          <li>
            <h6>최대가점</h6>
            <p>5점(2022년 기준)</p>
          </li>
          <li>
            <h6>영업점 상품판매 필수 자격증</h6>
            <p>각 1점</p>
          </li>
          <li>
            <h6>기타 보유 등급별 자격증</h6>
            <p>-</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <p class="silver">
          <strong>영업점 상품판매 필수 자격증</strong><br>
          펀드투자권유자문인력, 파생상품투자권유자문인력, 방카슈랑스4종(생명,손해,제3,변액)
        </p>
        <p class="silver">
          <strong>기타 보유 등급별 자격증</strong><br>
          A등급(5), B등급(4), C등급/직무인증제 LevelⅡ(3), D등급/직무인증제 LevelⅠ(2), E등급(1)
          ※ 연수규정 &gt; [별표4] 자격증별 마일리지 부여기준
        </p>
      </div>
    </div>
  </section>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">평가활용방안</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <div class="list-title">
          <h5>일반직원 (L0~L3)</h5>
          <p class="title-desc">해당 직급에서의 필수이수학점, 자격증취득가점(L1,L2)을 합산하여 개인평가에 반영</p>
        </div>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>전문직원 (S1~S3)</h5>
          <p class="title-desc">해당 직급에서의 신임/승격예비과정 점수 개인평가에 반영</p>
        </div>
      </div>
    </div>
  </section>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">주) 자격증 최대 가점 경과기간 운영</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <div class="list-title">
          <h5>L1</h5>
        </div>
        <ol class="adc-list wd-66">
          <li>
            <h6>2019년</h6>
            <p>최대 2점</p>
          </li>
          <li>
            <h6>2020년</h6>
            <p>최대 5점</p>
          </li>
          <li>
            <h6>2021년</h6>
            <p>최대 7점</p>
          </li>
          <li>
            <h6>2022년</h6>
            <p>최대 10점</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>L2</h5>
        </div>
        <ol class="adc-list wd-66">
          <li>
            <h6>2019년</h6>
            <p>최대 2점</p>
          </li>
          <li>
            <h6>2020년</h6>
            <p>최대 5점</p>
          </li>
          <li>
            <h6>2021년</h6>
            <p>최대 7점</p>
          </li>
          <li>
            <h6>2022년</h6>
            <p>최대 10점</p>
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "MobileCapabilitySystemScore",
}
</script>
