<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">자격증취득 가점현황</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <ol class="adc-list justify">
          <li>
            <h6>구분</h6>
            <p>평가점수</p>
          </li>
          <li>
            <h6>필수자격증취득점수</h6>
            <p>{{ maxLicenseScore > 0 ? `${result.addscoreSales}점` : '대상아님' }}</p>
          </li>
          <li>
            <h6>기타자격증취득점수</h6>
            <p>{{ maxLicenseScore > 0 ? `${result.addscoreEtc}점` : '대상아님' }}</p>
          </li>
          <li>
            <h6>자격증취득가점</h6>
            <p>{{ maxLicenseScore > 0 ? `${result.addscoreLic}점 (최대가점:${maxLicenseScore}점)` : '대상아님' }}</p>
          </li>
        </ol>
      </div>
    </div>
  </section>

  <section v-if="showMeta" class="content-section">
    <div class="adc-main-articles">
      <article class="neccesary">
        <h5 class="title">필수이수학점 (100점)</h5>
        <p class="silver text-center">직급별 필수과정(신임과정, 승격예비과정) 및 직무필수과정 이수실적 평가</p>
      </article>
      <article class="neccesary center-align">
        <p class="big-text">40점 (신임과정 수료 시*)</p>
        <img class="icon-adc-red" src="@/assets/lxp/mobile/images/adc/ic_plus_adc_red.svg">
        <p class="big-text">60점</p>
        <img class="icon-adc-red" src="@/assets/lxp/mobile/images/adc/ic_x.svg">
        <p class="big-text">(승격예비・직무필수과정 취득성적**)</p>
        <p class="big-text denominator">100</p>
      </article>
      <article class="neccesary">
        <p class="silver">* L2/L3 직원 신임과정 수료시 40점 부여<br>
          L0/L1 직원은 당해 직급의 KB은행실무종합과정(舊직원실무평가) 상위 성적 2회분의 평균 성적을 100분 비율로 환산하여 배점에 곱한 점수를 부여함<br>
          (단, 2회 미만 응시자의 경우 1회분의 성적을 적용)

          <br><br>
          ** 직무필수과정의 취득성적과 승격예비과정(집합의 취득성적을 평균하여 산출)</p>
      </article>
      <div class="ic-plus">
        <img src="@/assets/lxp/mobile/images/adc/ic_plus_adc.svg">
      </div>
      <article class="plus">
        <h5 class="title">자격증 취득가점 (직급별 최대 10, 5점이내)</h5>
        <p class="silver text-center">상품판매 필수 자격증 * 및<br>기타 보유 등급별 자격증 **</p>
      </article>
      <article class="plus center-align">
        <p class="big-text">L1 - 최대가점 10점 ・ L2 - 최대가점 5점</p>
      </article>
      <article class="plus">
        <p class="silver">* 펀드투자권유자문인력, 파생상품투자권유자문인력, 보험 4종(생명,손해,제3,변액보험판매관리사 4종 모두 보유시) : 가점 각 1점
          <br><br>** A등급(5), B등급(4), C등급/직무인증제 LevelⅡ(3), D등급/직무인증제 LevelⅠ(2), E등급(1) :  가점 L1 최대 7점 / L2 최대 5점
        </p>
      </article>
    </div>
  </section>

</template>
<script>

import {capabilityLicenseStatusSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityLicenseStatus",
  props: {
    result: Object,
    showMeta: Boolean
  },
  setup: capabilityLicenseStatusSetup
}
</script>
