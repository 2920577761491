<template>
  <div class="view-contents">
    <MobileCapabilityLicenseStatus
        :result="result"
    />

    <MobileCapabilitySalesLicense
        :result="result"
        :salesMeta="salesMeta"
    />

    <MobileCapabilityEtcLicense
        :result="result"
        :etc-items="etcItems"
     />
  </div>
</template>
<script>

import MobileCapabilityLicenseStatus from '@/components/capability/mobile/MobileCapabilityLicenseStatus';
import {capabilityLicenseSetup} from '@/assets/js/modules/capability/capability-setup';
import MobileCapabilitySalesLicense from '@/components/capability/mobile/MobileCapabilitySalesLicense';
import MobileCapabilityEtcLicense from '@/components/capability/mobile/MobileCapabilityEtcLicense';

export default {
  name: "MobileCapabilityLicense",
  components: {MobileCapabilityEtcLicense, MobileCapabilitySalesLicense, MobileCapabilityLicenseStatus},
  props: {
    result: Object,
  },
  setup: capabilityLicenseSetup
}
</script>
