<template>
  <div class="view-contents">

    <MobileCapabilityTotalStatus
        :result="result"
        :elements="elements"
    />

    <MobileCapabilityCourseStatus
        :result="result"
        :elements="elements"
    />

    <MobileCapabilityLicenseStatus
        :result="result"
        :show-meta="true"
    />


    <section class="content-section">
      <header class="section-header">
        <h4 class="title">평가활용방안</h4>
      </header>
      <div class="section-content">
        <div class="content-item">
          <div class="list-title">
            <h5>일반직원 (L0~L3)</h5>
            <p class="title-desc">해당 직급에서의 필수이수학점, 자격증취득가점(L1,L2)을 합산하여 개인평가에 반영</p>
          </div>
        </div>
        <div class="content-item">
          <div class="list-title">
            <h5>전문직원 (S1~S3)</h5>
            <p class="title-desc">해당 직급에서의 신임/승격예비과정 점수 개인평가에 반영</p>
          </div>
        </div>
      </div>
    </section>
<!--    <section class="content-section">-->
<!--      <header class="section-header">-->
<!--        <h4 class="title">주) 자격증 최대 가점 경과기간 운영</h4>-->
<!--      </header>-->
<!--      <div class="section-content">-->
<!--        <div class="content-item">-->
<!--          <div class="list-title">-->
<!--            <h5>L1</h5>-->
<!--          </div>-->
<!--          <ol class="adc-list wd-66">-->
<!--            <li>-->
<!--              <h6>2019년</h6>-->
<!--              <p>최대 2점</p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <h6>2020년</h6>-->
<!--              <p>최대 5점</p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <h6>2021년</h6>-->
<!--              <p>최대 7점</p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <h6>2022년</h6>-->
<!--              <p>최대 10점</p>-->
<!--            </li>-->
<!--          </ol>-->
<!--        </div>-->
<!--        <div class="content-item">-->
<!--          <div class="list-title">-->
<!--            <h5>L2</h5>-->
<!--          </div>-->
<!--          <ol class="adc-list wd-66">-->
<!--            <li>-->
<!--              <h6>2019년</h6>-->
<!--              <p>최대 2점</p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <h6>2020년</h6>-->
<!--              <p>최대 5점</p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <h6>2021년</h6>-->
<!--              <p>최대 7점</p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <h6>2022년</h6>-->
<!--              <p>최대 10점</p>-->
<!--            </li>-->
<!--          </ol>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>
<script>

import MobileCapabilityCourseStatus from '@/components/capability/mobile/MobileCapabilityCourseStatus';
import MobileCapabilityLicenseStatus from '@/components/capability/mobile/MobileCapabilityLicenseStatus';
import MobileCapabilityTotalStatus from '@/components/capability/mobile/MobileCapabilityTotalStatus';

export default {
  name: "MobileCapabilityResult",
  components: {MobileCapabilityTotalStatus, MobileCapabilityLicenseStatus, MobileCapabilityCourseStatus},
  props: {
    result: Object,
    elements: Array
  },
}
</script>
