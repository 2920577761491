<template>
  <div class="view-contents">

    <article class="content-section">
      <header class="section-header">
      </header>
      <!-- badge-content-container -->
      <div class="badge-content-container">
        <div class="badge-content-item" v-for="(subj, index) in reqsubjGroup" :key="index">
          <div class="item-row row-content">
            <div class="item-badge badge" :class="subj.maxCnt==subj.curCnt ? 'badge-primary' : 'badge-silver'">
              <span class="badge-text">{{ subj.maxCnt==subj.curCnt ? '완료' : '미완료' }}</span>
            </div>
            <h5 class="item-title">{{ subj.name }}</h5>
            <strong class="item-text text-gold">{{ subj.curCnt }}/ {{subj.maxCnt}}</strong>
          </div>
          <div class="item-row row-buttons">
            <button class="kb-btn kb-btn-outline" @click="showReqSubjListMobile(subj.name)">
              <span class="text">교과목 보기</span>
            </button>
          </div>
        </div>
      </div>
      <!-- //badge-content-container -->
    </article>


    <MobileCapabilityGradeTable
        v-if="elements.includes('R')"
        :div-cds="elements"
        :items="gradeItems"
        :result="result"
        :disp-div="'reqsubjOnly'"

    />

    <section class="content-section">
      <div class="section-content">
        <div class="content-item">
          <div class="list-title">
            <h5>입행 / 승격일 2010.12.24 기준 직무필수 과정 확대</h5>
          </div>
          <div class="desc">
            <p class="title-desc ">L3- 2개 &gt; 3개</p>
            <p class="title-desc">L2 및 L1 - 3개 &gt; 4개</p>
          </div>
        </div>
        <div class="content-item">
          <div class="list-title">
            <h5>경과조치 관련문서</h5>
          </div>
          <ol class="adc-list wd-112">
            <li>
              <div class="link"><a href="javascript:" @click="downloadFileDownMng(guideFile[0].fileKey)">문서보기</a></div>
              <p>연수규정 및 역량개발평가지침 일부 개정 (인재개발원(규정) 제 100호, 2011.02.07)</p>
            </li>
            <li>
              <div class="link"><a href="javascript:" @click="downloadFileDownMng(guideFile[1].fileKey)">문서보기</a></div>
              <p>역량개발평가(연수원평가)특례 인정 및 주요 문의사항 통보(연수원 652, 2006.12.11)</p>
            </li>
          </ol>
        </div>
        <div class="content-item">
          <div class="list-title">
            <h5>직급별 직무필수과정 관련문서</h5>
          </div>
          <ol class="adc-list wd-112">
            <li>
              <div class="link"><a href="javascript:" @click="downloadFileDownMng(guideFile[2].fileKey)">문서보기</a></div>
              <p>연수규정 및 역량개발평가지침 일부 개정(인재개발부(규정) 제 627호, 2014.08.01)</p>
            </li>
          </ol>
        </div>
      </div>
    </section>
  </div>

  <MobileCapabilityReqSubjPopup v-if="popupToggle" v-model:toggle="popupToggle" :div-key="divModalKey" :result="result" :reqDivSubjList="reqDivSubjList" />
</template>
<script>

import {capabilityReqSubjSetup} from '@/assets/js/modules/capability/capability-setup';
import MobileCapabilityGradeTable from '@/components/capability/mobile/MobileCapabilityGradeTable';
import MobileCapabilityReqSubjPopup from "@/components/capability/mobile/MobileCapabilityReqSubjPopup";

export default {
  name: "MobileCapabilityReqSubj",
  components: {MobileCapabilityGradeTable, MobileCapabilityReqSubjPopup},
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityReqSubjSetup
}
</script>
