<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">필수이수학점현황</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <ol class="adc-list wd-112">
          <li>
            <h6>구분</h6>
            <p>평가점수</p>
          </li>
          <li>
            <h6>{{ requirementText }}</h6>
            <p v-if="isFoundation">{{ beginScore }}<br>L0/L1 은 KB은행실무종합과정 (舊직원실무평가) 성적 상위 2회분 평균성적으로 평가</p>
            <p v-else>{{ beginScore }}</p>
          </li>
          <li>
            <h6>승격예비 ・ 직무필수과정 *</h6>
            <p>{{ gradeScore }}<br>
              <strong v-if="isRequirement"  :class="{'emphasize' : result.pilsupassCnt !== 0 && result.pilsupassCnt >= result.jikmuReqCountDefault}">직무필수과정 {{ result.pilsupassCnt }}/{{ result.jikmuReqCountDefault }} {{result.pilsupassCnt >=result.jikmuReqCountDefault ? '수료' : '미수료'}}</strong><br>
              <strong :class="{'emphasize' : (result.jkgupGradeBookingId  > 0 || result.jkgupGradeLrnMapngRsltSumupSn > 0)}">예비과정({{ result.preclassname }})</strong></p>
          </li>
          <li>
            <h6>계(100점만점)</h6>
            <p>{{ totalScore }}</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <p class="silver">* 직무필수과정의 취득성적과 승격예비과정 종합평가의 취득성적을 평균하여 산출함</p>
      </div>
    </div>
  </section>

</template>
<script>

import {capabilityCourseStatusSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityCourseStatus",
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityCourseStatusSetup
}
</script>
