<template>
  <div class="review-contents">
    <p>직무역량 강화를 위하여 은행에서 정한 직급별/CDP별 연수체계에 의거하여 이수한 직급별 필수과정 및 직무 필수과정 이수실적을
      당해 직급기간에 누적하여 평가하는 것을 의미합니다.
    </p>
  </div>
  <div class="view-contents">
    <section class="section-menu">
      <ul>
        <li v-for="(system, index) in systemRoutes" :class="{'is-active': index === currentIndex}" :key="index">
          <a href="javascript:" @click="currentIndex = index">{{ system }}</a>
        </li>
      </ul>
    </section>

    <MobileCapabilitySystemTrain
        v-if="currentIndex === 0"
    />
    <MobileCapabilitySystemScore
        v-else-if="currentIndex === 1"
    />
  </div>

</template>
<script>

import MobileCapabilitySystemTrain from '@/components/capability/mobile/MobileCapabilitySystemTrain';
import MobileCapabilitySystemScore from '@/components/capability/mobile/MobileCapabilitySystemScore';
import {capabilitySystemSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilitySystem",
  components: {MobileCapabilitySystemScore, MobileCapabilitySystemTrain},
  props: {},
  setup: capabilitySystemSetup
}
</script>
