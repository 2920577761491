<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">영업점 상품판매 필수 자격증</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <div class="list-title">
          <h5>펀드투자권유자문인력</h5>
        </div>
        <ol class="adc-list wd-66">
<!--          <li>-->
<!--            <h6>점수</h6>-->
<!--            <p>1</p>-->
<!--          </li>-->
          <li>
            <h6>취득일자</h6>
            <p>{{ salesMeta.fundDate ? salesMeta.fundDate : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ salesMeta.fundDate ? 'Y' : 'N' }}</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>파생상품투자권유자문인력</h5>
        </div>
        <ol class="adc-list wd-66">
<!--          <li>-->
<!--            <h6>점수</h6>-->
<!--            <p>1</p>-->
<!--          </li>-->
          <li>
            <h6>취득일자</h6>
            <p>{{ salesMeta.derivation ? salesMeta.derivation : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ salesMeta.derivation ? 'Y' : 'N' }}</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>생명보험</h5>
        </div>
        <ol class="adc-list wd-66">
<!--          <li>-->
<!--            <h6>점수</h6>-->
<!--            <p>1</p>-->
<!--          </li>-->
          <li>
            <h6>취득일자</h6>
            <p>{{ salesMeta.lifeInsurance ? salesMeta.lifeInsurance : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ salesMeta.lifeInsurance ? 'Y' : 'N' }}</p>
          </li>
        </ol>
      </div>
      <div class="content-item">
        <div class="list-title">
          <h5>손해보험</h5>
        </div>
        <ol class="adc-list wd-66">
<!--          <li>-->
<!--            <h6>점수</h6>-->
<!--            <p>1</p>-->
<!--          </li>-->
          <li>
            <h6>취득일자</h6>
            <p>{{ salesMeta.generalInsurance ? salesMeta.generalInsurance : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ salesMeta.generalInsurance ? 'Y': 'N' }}</p>
          </li>
        </ol>
      </div>

      <div class="content-item">
        <div class="list-title">
          <h5>제3보험</h5>
        </div>
        <ol class="adc-list wd-66">
          <!--          <li>-->
          <!--            <h6>점수</h6>-->
          <!--            <p>1</p>-->
          <!--          </li>-->
          <li>
            <h6>취득일자</h6>
            <p>{{ salesMeta.thirdInsurance ? salesMeta.thirdInsurance : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ salesMeta.thirdInsurance ? 'Y': 'N' }}</p>
          </li>
        </ol>
      </div>

      <div class="content-item">
        <div class="list-title">
          <h5>제3보험</h5>
        </div>
        <ol class="adc-list wd-66">
          <!--          <li>-->
          <!--            <h6>점수</h6>-->
          <!--            <p>1</p>-->
          <!--          </li>-->
          <li>
            <h6>취득일자</h6>
            <p>{{ salesMeta.variableInsurance ? salesMeta.variableInsurance : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ salesMeta.variableInsurance ? 'Y': 'N' }}</p>
          </li>
        </ol>
      </div>

    </div>
  </section>

</template>
<script>

export default {
  name: "MobileCapabilitySalesLicense",
  components: {},
  props: {
    result: Object,
    salesMeta: Object
  }
}
</script>
