<template>
  <div class="main-header">
    <div class="header-snb">
      <nav class="snb">
        <ul class="snb-list">
          <li v-for="(menu, index) in menus"  class="snb-item"  :key="index">
            <button :class="{'is-active': menu.view === currentView}" @click="moveRoute(menu)">{{ menu.name }}</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>

import {capabilityRouteSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityRoute",
  setup: capabilityRouteSetup
}
</script>
