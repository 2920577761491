<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">평가현황</h4>
    </header>
    <div class="section-content">
      <div class="content-item">
        <ol class="adc-list justify">
          <li>
            <h6>구분</h6>
            <p>평가점수</p>
          </li>
          <li>
            <h6>필수이수학점</h6>
            <p>{{ courseScore }}</p>
          </li>
          <li>
            <h6>자격증취득가점</h6>
            <p>{{ licenseScore }}</p>
          </li>
          <li>
            <h6>계(100점만점)</h6>
            <p>{{ totalScore }}</p>
          </li>
        </ol>
      </div>
    </div>
  </section>

</template>
<script>

import {capabilityTotalStatusSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityTotalStatus",
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityTotalStatusSetup
}
</script>
