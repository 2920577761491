<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">{{ title }}</h4>
    </header>
    <div class="section-content">
      <div v-for="(item, index) in items" class="content-item" :key="index" :class="{'kb-line-through': isDashLine}">
        <ol class="adc-list wd-112">
          <li>
            <h6>참가연도</h6>
            <p>{{ item.ended }}</p>
          </li>

          <li>
            <h6>과정명</h6>
            <p>{{ item.subjnm }}</p>
          </li>
          <li>
            <h6>수료여부</h6>
            <p v-if="divCd === 'O'">수료</p>
            <p v-else>{{ item.grayn === 'Y' ? '수료' : '미수료' }}</p>
          </li>
          <li>
            <h6>취득점수</h6>
            <p>{{ divCd === 'O' ? 100 : item.paccept }}점</p>
          </li>
          <li v-if="divCd === 'S'">
            <h6>응시구분</h6>
            <p>{{ getSubCategory(item.subjnm) }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>{{ item.reflectTarget }}</p>
          </li>
        </ol>
      </div>
    </div>
  </section>

</template>
<script>

import {capabilityBeginTableSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityBeginTable",
  components: {},
  props: {
    divCd: String,
    items: Array
  },
  setup: capabilityBeginTableSetup
}
</script>
