<template>

  <section class="content-section">
    <header class="section-header">
      <h4 class="title">기타 보유 등급별 자격증</h4>
    </header>
    <div class="section-content">
      <div v-for="(item, index) in etcItems" :key="index" class="content-item">
        <div class="list-title">
          <h5>{{ item.lcsnNm }}</h5>
        </div>
        <ol class="adc-list wd-66">
          <li>
            <h6>등급</h6>
            <p>{{ item.grd1Cd }}</p>
          </li>
          <li>
            <h6>점수</h6>
            <p>{{ result.ficjkgup.indexOf('L1') > -1 || result.ficjkgup.indexOf('L2') > -1 ? item.lcsnScore : '대상아님' }}</p>
          </li>
          <li>
            <h6>취득일자</h6>
            <p>{{ item.passAnnonYmd }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p>-</p>
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "MobileCapabilityEtcLicense",
  components: {},
  props: {
    result: Object,
    etcItems: Array
  },
}
</script>
