<template>
  <LxpMobileHeader title="역량개발평가">
    <template v-slot:right>
      <div v-if="fileDownKey && isMainActive" class="util util-download">
        <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
          첨부파일
          <i class="icon-download"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>

<!--  <LxpMobileHeader title="역량개발평가">-->
<!--    <template v-slot:right>-->
<!--      <div v-if="guideFile.length > 0 && isMainActive" class="util util-download">-->
<!--        <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">-->
<!--          첨부파일-->
<!--          <i class="icon-download"></i>-->
<!--        </a>-->
<!--      </div>-->
<!--    </template>-->
<!--  </LxpMobileHeader>-->

<!--  <lxp-main-header :show-title="readies.result" :show-breadcrumb="true"  :file-down-key-list="fileDownKeyList">-->
<!--    <template v-slot:action>-->
<!--      <CapabilityRoute v-if="readies.result"/>-->
<!--    </template>-->

  <main class="kb-main" id="kb-adc2">
    <!-- main-header -->
    <MobileCapabilityRoute />
    <!-- //main-header -->
    <!-- main-content -->
    <div v-if="readies.result" class="main-content main-component">

      <MobileCapabilityResult
        v-if="currentView === 'result'"
        :result="result"
        :elements="elements"
      />
      <MobileCapabilityCredit
        v-else-if="currentView === 'credit'"
        :result="result"
        :elements="elements"
      />

      <MobileCapabilityReqSubj
          v-else-if="currentView === 'reqsubj'"
          :result="result"
          :elements="elements"
      />
      <MobileCapabilityLicense
        v-else-if="currentView === 'certificate'"
        :result="result"
        :elements="elements"
      />
      <MobileCapabilitySystem
        v-else-if="currentView === 'system'"
      />

    </div>
    <!-- //main-content -->
  </main>
  <SupportMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="fileDownKey"/>
</template>

<script>
import {capabilitySetup} from '@/assets/js/modules/capability/capability-setup';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileCapabilityRoute from '@/components/capability/mobile/MobileCapabilityRoute';
import MobileCapabilityResult from '@/components/capability/mobile/MobileCapabilityResult';
import MobileCapabilityReqSubj from '@/components/capability/mobile/MobileCapabilityReqSubj';
import MobileCapabilityCredit from '@/components/capability/mobile/MobileCapabilityCredit';
import MobileCapabilityLicense from '@/components/capability/mobile/MobileCapabilityLicense';
import MobileCapabilitySystem from '@/components/capability/mobile/MobileCapabilitySystem';
import SupportMobileFileAtch from "@/views/pages/support/mobile/SupportMobileFileAtch";

export default {
  name: 'MobileMyCapability',
  components: {
    MobileCapabilitySystem,
    MobileCapabilityLicense,
    MobileCapabilityReqSubj,
    MobileCapabilityCredit,
    MobileCapabilityResult,
    MobileCapabilityRoute,
    LxpMobileHeader,
    SupportMobileFileAtch,

  },
  setup: capabilitySetup
};
</script>
